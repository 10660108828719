@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.popin {
   background-color: white;
   height: auto;
   min-width: 480px;
   width: 680px;
   padding: 25px 50px;
   margin: 0 auto;
   position: absolute;
   top: 50%;
   right: 50%;
   transform: translate(50%, -50%);
   border-radius: 10px;
   @include shadow;
   overflow-y: scroll;
   overflow-x: hidden;
   scrollbar-width: thin;
   .barreButton {
      margin-top: 0;
   }
}

.page_content_detailvente {
   position: relative;

   .input-text {
      .MuiFormControl-root {
         .Mui-error {
            &.MuiFormHelperText-root {
               top: 50px;
            }
         }
      }
   }

   .cross {
      position: sticky;
      top: 0;
      right: 0;
      width: 100%;
      cursor: pointer;

      display: flex;
      justify-content: flex-end;
      svg {
         width: 16px;
         height: 16px;
      }
   }
   .container {
      h3 {
         margin: 10px auto 30px;
      }
      .popin_detailvente_header {
         display: flex;
         align-items: center;
         flex-direction: column;
         
         .round-blue {
            height: 24px;
            width: 24px;
            background-color: $blue;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            margin-top: 10px;
            .left {
               margin-right: 2px;
            }
            .right {
               margin-left: 2px;
            }
         }
         .choice-management {
            display: flex;
            align-items: center;
         }
         .title {
            margin: 27px 12px 15px 12px !important;
            color: $green;
         }
         .subtitle {
            margin: 0;
            color: $blue;
         }
      }
      .main_container_detailvente {
         margin-top: 15px;
         .MuiGrid-container {
            .MuiGrid-item {
               margin-bottom: 20px;
               p {
                  margin: 0;
               }
            }
         }
         .align {
            display: flex;
            justify-content: flex-end;
            align-items: center;
         }
         .statut-date {
            display: flex;
            align-items: center;
            p {
               margin: 0;
               font-weight: bold;
            }
            .text_motif,
            .text_motif p {
               margin: 0;
               font-weight: bold;
            }
            svg {
               margin-top: -15px;
               margin-left: -2px;
            }
         }
      }
   }
}

@media only screen and (max-width: 1024px) {
   .popin {
      width: auto;
   }
   .container_btn_popinDetail {
      flex-direction: column;
      button {
         &:first-of-type {
            margin: 0 auto 15px;
         }
      }
   }
   .team_container {
      .container_combobox {
         .MuiFormControl-root {
            width: 230px;
         }
      } 
   }
}

.gender-bloc {
   margin-left: 10px;
   margin-bottom: 20px;
}