@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.popin {
  .page_content_annule {
    .container {
        header {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            margin-bottom: 5px;
            flex-direction: column;
            padding-top:0;
            .idLabel {
                margin-top: 20px;
            } 
            .title {
                margin-top: 0;
            }  
            .cross {
                top: 24px;
            }        
        }
        .main_container_annule {
            .title_line {
                color: $blue_petrol_strong;
                font-weight: 500;
                margin-top: 15px;
                margin-bottom: 10px;
                
            }
            .container_combobox .MuiFormControl-root {
                width: 100%;
            }
            .btn_container {
                display: flex;
                justify-content:center;
                margin-top: 25px;
                button {
                    &:first-of-type {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
  }
}
.checkboxes,
.checkboxes-label {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}